<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>{{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}</h2>
            <div class="box-tools pull-right">
              <!-- <button class="btn btn-box-tool" v-if="canMergeUsers" @click="showWondeMerge"><i class="fas fa-project-diagram"></i></button> -->
              <button type="button" class="button" data-toggle="modal" data-target="#addPupilsModal" @click="showAddModal">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add {{ $store.state.user.locale === 'en_GB' ? 'Pupils' : 'Students' }}</span>
              </button>
            </div>
            <p>Please note that we do not store any passwords in plain text so it is important that you keep a separate record of pupil login details. Password data will be stored on your computer only until you sign out or click &ldquo;Forget Passwords&rdquo; and cannot be recovered.</p>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div v-if="canMergeUsers" id="canMergeUsersInfoBox">
              <div class="notification">
                <p><b>You have a mixture of <span v-if="numberWondePupils">({{ numberWondePupils }}) Wonde</span><span v-if="numberCleverPupils">({{ numberCleverPupils }}) Clever</span> and ({{ numberUnlinkedPupils }}) unlinked {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}. Select two users to merge ({{ checkedRows.length }} selected).</b></p><p class="has-text-right" />
              </div>
              <p>
                <b-radio
                  v-model="wondeMergeRadio"
                  native-value="all"
                  @input="searchDidChange"
                >
                  All
                </b-radio>
                <b-radio
                  v-model="wondeMergeRadio"
                  native-value="unlinked"
                  @input="searchDidChange"
                >
                  Unlinked
                </b-radio>
                <b-radio
                  v-if="numberWondePupils"
                  v-model="wondeMergeRadio"
                  native-value="wonde"
                  @input="searchDidChange"
                >
                  Wonde
                </b-radio>
                <b-radio
                  v-if="numberCleverPupils"
                  v-model="wondeMergeRadio"
                  native-value="clever"
                  @input="searchDidChange"
                >
                  Clever
                </b-radio>
              </p>
            </div>
            <div v-if="pupilData" class="col-sm-12 table-responsive" style="overflow: visible">
              <button v-if="passwordExports.length > 0" type="button" class="btn button is-warning pull-right" @click.prevent="print">
                Download Logins
              </button>
              <button v-if="passwordExports.length > 0" type="button" class="btn button is-danger pull-right" style="margin-right:5px;" @click.prevent="forgetPasswords">
                Forget Passwords
              </button>
              <div v-if="passwordExports.length > 0" class="is-pulled-right" style="clear:right">
                <small>N.B. Password data will be lost on logout. </small>
              </div>
            </div>
            <div v-if="pupilData" class="col-sm-12 table-responsive" style="overflow: visible">
              <button type="button" class="button is-link" @click="downloadPupils">
                Download CSV
              </button>
              <div class="btn-group dropdown-parent">
                <button
                  type="button"
                  class="btn button is-link dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :disabled="checkedRows.length == 0 ? 'disabled' : null"
                >
                  Add to Group&nbsp;<span class="caret" />
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" style="max-height: 250px; overflow-y: auto;">
                  <li v-if="!groupData">
                    <a class="nogo" tabindex="-1" href="#" @click.prevent=""> -- No Groups --</a>
                  </li>
                  <li v-for="(group, index) in groupData">
                    <a :tabindex="index" href="#" @click.prevent="assignToGroup(group.id)">{{ group.name }}</a>
                  </li>
                </ul>
              </div>
              <div class="btn-group dropdown-parent">
                <button
                  v-if="$store.state.user.school.admin"
                  type="button"
                  class="btn button is-link dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :disabled="checkedRows.length == 0 ? 'disabled' : null"
                >
                  Move to Group&nbsp;<span class="caret" />
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" style="max-height: 250px; overflow-y: auto;">
                  <li v-if="!groupData">
                    <a class="nogo" tabindex="-1" href="#" @click.prevent=""> -- No Groups --</a>
                  </li>
                  <li v-for="(group, index) in groupData">
                    <a :tabindex="index" href="#" @click.prevent="moveToGroup(group.id)">{{ group.name }}</a>
                  </li>
                </ul>
              </div>

              <button
                v-if="$store.state.user.school.admin"
                type="button"
                class="btn button is-link"
                data-toggle="modal"
                data-target="#passwordModal"
                :disabled="checkedRows.length == 0 ? 'disabled' : null"
              >
                Set Password
              </button>
              <button
                v-if="$store.state.user.school.admin"
                type="button"
                class="btn button is-link"
                data-toggle="modal"
                data-target="#regionModal"
                :disabled="checkedRows.length == 0 ? 'disabled' : null"
              >
                Set Region
              </button>
              <button v-if="$store.state.user.school.admin" type="button" class="btn button is-danger" :disabled="checkedRows.length == 0 ? 'disabled' : null" @click.prevent="deleteMultiplePupils()">
                Delete
              </button>
              <button v-if="$store.state.user.school.admin" type="button" class="button is-warning" :disabled="checkedRows.length != 2 ? 'disabled' : null" @click="showWondeMerge">
                Merge
              </button>
              <div style="margin-top:10px;">
                <b-field>
                  <b-input
                    v-model="searchPhrase"
                    placeholder="Search..."
                    type="search"
                    icon-pack="fas"
                    icon="search"
                    @input="searchDidChange"
                  />
                </b-field>
              </div>
              <b-table
                v-if="filteredPupilData && pupilData"
                :data.sync="filteredPupilData"
                :paginated="true"
                :per-page="10"
                :mobile-cards="true"
                :striped="true"
                :selectable="false"
                default-sort-direction="asc"
                default-sort="name"
                :checked-rows.sync="checkedRows"
                checkable
                style="overflow-x:auto;"
                @check="didCheck"
              >
                <template slot-scope="props">
                  <b-table-column field="avatar" label="Pupil">
                    <template>
                      <AvatarView
                        v-if="props.row.total_score != null"
                        :key="'av-' + props.row.id"
                        style="float:left; margin-right:10px;"
                        :uniq="'user-'+props.row.id"
                        :data="(props.row.avatar && props.row.avatar != '') ? JSON.parse(props.row.avatar) : {}"
                        scale-factor="0.35"
                      />
                      <br><router-link :to="'/pupils/' + props.row.username" tag="button" type="button" class="button is-small is-link">
                        Details
                      </router-link>
                    </template>
                  </b-table-column>
                  <b-table-column field="name" label="Name" sortable>
                    <h4>{{ props.row.name }}</h4><span class="badge"><i class="fas fa-tv" /> {{ props.row.display_name == '' ? props.row.name : props.row.display_name }}</span>
                    <button class="button is-small" @click.prevent="setDisplayName(props.row)">
                      <i class="fas fa-edit" />
                    </button>
                  </b-table-column>
                  <b-table-column field="groups" label="Groups" width="200" style="max-width: 40%" sortable>
                    <p>{{ props.row.groups ? props.row.groups.split(',').join(', ') : '' }}</p>
                  </b-table-column>
                  <b-table-column field="username" label="Username" sortable>
                    <p>{{ props.row.username }}</p>
                  </b-table-column>
                  <b-table-column field="upn" label="UPN" sortable>
                    <p>{{ props.row.upn }}</p>
                  </b-table-column>
                  <b-table-column field="locale" label="Region" width="20">
                    <span v-html="localeInfo(props.row.locale)" />
                  </b-table-column>
                  <b-table-column field="password" label="Password">
                    <p>{{ props.row.password || '******' }}</p>
                    <p v-if="props.row.wonde_upi || props.row.clever_oid" /></p><figure v-if="props.row.wonde_upi" class="figure">
                      <img src="/img/wondesmall.png" style="width: 32px;">
                    </figure>
                    <figure v-if="props.row.clever_oid" class="figure">
                      <img src="/img/clevericon.png" style="width: 32px;">
                    </figure>
                    </p>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered content">
                      <p>
                        <b-icon
                          custom-class="far"
                          pack="fa"
                          icon="frown"
                          size="is-large"
                        />
                      </p>
                      <p>Nothing here.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Pupil Modal -->
    <AddPupils v-if="showModal" @pupils-added="didAddPupils" @close="hideAddModal" />
    <!-- Set Password Modal -->
    <div id="passwordModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="passwordModalLabel">
      <div class="modal-dialog" role="document" style="margin: 10px auto">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearPasswordBeingSet">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 id="myModalLabel" class="modal-title">
              Set Pupil Passwords
            </h4>
          </div>
          <div class="modal-body">
            <p>Enter a password below or click "Set Random" to generate random passwords for all pupils.</p>
            <form style="width:100%">
              <div class="form-group">
                <label for="inputChangePassword">Password</label>
                <input id="inputChangePassword" v-model="passwordBeingSet" class="form-control" placeholder="Password">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn button" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn button is-link" data-dismiss="modal" :disabled="(passwordBeingSet.length > 0) ? 'disabled' : null" @click="didSetRandomPasswords">
              Set Random
            </button>
            <button type="button" class="btn button is-link" data-dismiss="modal" :disabled="(passwordBeingSet.length < 6) ? 'disabled' : null" @click="didSetPasswords">
              Set Password
            </button>
          </div>
        </div>
      </div>
    </div>

    <div id="regionModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="regionModalLabel">
      <div class="modal-dialog" role="document" style="margin: 10px auto">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearRegionBeingSet">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 id="myModalLabel" class="modal-title">
              Set Pupil Region
            </h4>
          </div>
          <div class="modal-body">
            <form style="width:100%">
              <div class="form-group">
                <label for="inputChangeRegion">Region</label>
                <select id="inputChangeRegion" v-model="locale" class="form-control input-group">
                  <option value="en_AU">
                    🇦🇺 Australia
                  </option>
                  <option value="en_IE">
                    🇮🇪 Ireland
                  </option>
                  <option value="en_NZ">
                    🇳🇿 New Zealand
                  </option>
                  <option value="en_GB">
                    🇬🇧 United Kingdom
                  </option>
                  <option value="en_US">
                    🇺🇸 United States
                  </option>
                </select>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn button" data-dismiss="modal">
              Close
            </button>
            <button type="button" class="btn button is-link" data-dismiss="modal" @click="didSetRegion">
              Set Region
            </button>
          </div>
        </div>
      </div>
    </div>
    <WondeMergeUsers v-if="showWondeMergeUsersList" :users="checkedRows" :pupils="true" @close="hideWondeMerge" @success="didMerge" />
  </section>
  <!-- /.content -->
</template>
<script>
import $ from 'jquery'
import { Validator } from 'vee-validate'
import AddPupils from '@/components/views/components/AddPupils'
import LoginDetails from '@/components/views/components/LoginDetails'
import utils from '@/utils'
import loginPDF from '@/loginPDF'
import AvatarView from '@/components/views/AvatarView'
import WondeMergeUsers from '@/components/views/components/WondeMergeUsers'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'
import store from '@/store'
import { request } from '@/edshed-common/api'

export default {
  name: 'Pupils',
  components: {
    AddPupils,
    LoginDetails,
    AvatarView,
    WondeMergeUsers
  },
  // Redirect to new home for non-superusers
  beforeRouteEnter (to, from, next) {
    if (store.state.user && store.state.user.superuser) {
      next()
    } else {
      let locale = 'en-gb'
      if (store.state.user && store.state.user.locale && store.state.user.locale) {
        locale = store.state.user.locale.toLowerCase().replace('_', '-')
      }
      window.location.replace(`https://www.edshed.com/${locale}/pupils`)
    }
  },
  mixins: [
    ModalMixin
  ],
  data (router) {
    return {
      loading: '',
      pupilData: null,
      filteredPupilData: null,
      searchPhrase: '',
      groupData: null,
      response: 'Data Loading...',
      showModal: false,
      showWondeMergeUsersList: false,
      wondeMergeRadio: 'all',
      modalResponse: '',
      username: '',
      name: '',
      email: '',
      password: '',
      passwordBeingSet: '',
      checkedRows: [],
      locale: this.$store.state.user.school.locale
    }
  },
  computed: {
    passwordExports () {
      const exp = []
      let src = this.pupilData
      if (this.checkedRows.length > 0) {
        src = this.checkedRows
      }
      if (!this.pupilData) {
        return exp
      }
      for (let i = 0; i < src.length; i++) {
        if (!src[i].password) {
          const p = this.passwordForUsername(src[i].username)
          if (p) {
            src[i].password = this.passwordForUsername(src[i].username)
          }
        }
        if (src[i].password && src[i].password !== '') {
          exp.push(src[i])
        }
      }
      return exp
    },
    canMergeUsers () {
      if (this.pupilData) {
        const wps = this.numberWondePupils
        const cps = this.numberCleverPupils
        const ps = this.numberUnlinkedPupils
        console.log('wps: ' + wps)
        console.log('cps: ' + cps)
        console.log('ps: ' + ps)
        if (ps && (wps || cps)) {
          return true
        }
      }
      return false
    },
    numberUnlinkedPupils () {
      return this.pupilData.filter(p => (p.wonde_upi === null && p.clever_oid === null)).length
    },
    numberWondePupils () {
      return this.pupilData.filter(p => p.wonde_upi != null).length
    },
    numberCleverPupils () {
      return this.pupilData.filter(p => p.clever_oid != null).length
    }
  },
  mounted () {
    if (this.$store.state.user.school.org_type === 'district') {
      this.$router.push({ path: '/' })
      return
    }
    this.$nextTick(() => {
      this.getListData()
      this.getGroupData()
    })
    const dictionary = {
      en_GB: {
        attributes: {
          name: 'Name',
          email: 'Email',
          username: 'Username',
          password: 'Password'
        }
      }
    }
    Validator.localize('en_GB', dictionary.en_GB)
  },
  // beforeRouteLeave (to, from, next) {
  //   console.log('leaving')
  //   if (this.passwordExports.length > 0) {
  //     const answer = window.confirm('Pupil passwords will be lost once you navigate away from this page. If you have not printed / saved them click Cancel.')
  //     if (answer) {
  //       next()
  //     } else {
  //       next(false)
  //     }
  //   } else {
  //     next()
  //   }
  // },
  methods: {
    downloadPupils () {
      let filter = '*'
      if (this.checkedRows.length) {
        filter = this.checkedRows.map(p => p.id).join(',')
      }
      console.log(filter)
      request('GET', 'users/me/school/pupils/csv?filter=' + filter, null)
        .then((response) => {
          // console.log('readerData: ' + this.readerData)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'pupils.csv')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    showWondeMerge () {
      this.showWondeMergeUsersList = true
    },
    hideWondeMerge () {
      this.showWondeMergeUsersList = false
    },
    didMerge () {
      this.checkedRows = []
      this.hideWondeMerge()
      this.getListData()
    },
    didCheck () {
      this.$forceUpdate()
    },
    searchDidChange () {
      // console.log(this.searchPhrase)
      if (this.searchPhrase !== '') {
        this.filteredPupilData = []
        for (let i = 0; i < this.pupilData.length; i++) {
          // console.log(this.pupilData[i].name.toLowerCase())
          if (this.pupilData[i].name.toLowerCase().includes(this.searchPhrase.toLowerCase()) || this.pupilData[i].username.toLowerCase().includes(this.searchPhrase.toLowerCase()) || (this.pupilData[i].groups != null && this.pupilData[i].groups.toLowerCase().includes(this.searchPhrase.toLowerCase())) || (this.pupilData[i].upn && this.pupilData[i].upn.toLowerCase().includes(this.searchPhrase.toLowerCase())) || (this.pupilData[i].email && this.pupilData[i].email.toLowerCase().includes(this.searchPhrase.toLowerCase()))) {
            this.filteredPupilData.push(this.pupilData[i])
          }
        }
      } else {
        this.filteredPupilData = this.pupilData
      }
      if (this.canMergeUsers) {
        if (this.wondeMergeRadio === 'wonde') {
          this.filteredPupilData = this.filteredPupilData.filter(p => p.wonde_upi !== null)
        } else if (this.wondeMergeRadio === 'clever') {
          this.filteredPupilData = this.filteredPupilData.filter(p => p.clever_oid !== null)
        } else if (this.wondeMergeRadio === 'unlinked') {
          this.filteredPupilData = this.filteredPupilData.filter(p => (p.wonde_upi === null && p.clever_oid === null))
        }
      }
      this.$forceUpdate
    },
    localeInfo (locale) {
      let loc = '<abbr title="United Kingdom">🇬🇧</abbr>'
      if (locale === 'en_AU') {
        loc = '<abbr title="Australia">🇦🇺</abbr>'
      } else if (locale === 'en_IE') {
        loc = '<abbr title="Ireland">🇮🇪</abbr>'
      } else if (locale === 'en_NZ') {
        loc = '<abbr title="New Zealand">🇳🇿</abbr>'
      } else if (locale === 'en_US') {
        loc = '<abbr title="United States">🇺🇸</abbr>'
      }
      return loc
    },
    print () {
      // window.print()
      this.generatePDF()
      alert('Your login slips have been downloaded. We recommend you save a copy as password data will be lost when you sign out.')
    },
    generatePDF () {
      loginPDF.generatePDFForUsers(this.passwordExports)
    },
    passwordForUsername (username) {
      if (window.localStorage) {
        return window.localStorage.getItem('password:' + username)
      } else {
        return null
      }
    },
    clearPasswordBeingSet () {
      this.passwordBeingSet = ''
    },
    didSetPasswords () {
      // generate array of user : password
      const ids = []
      for (let i = 0; i < this.checkedRows.length; i++) {
        ids.push({ id: this.checkedRows[i].id, password: this.passwordBeingSet })
      }
      this.setPupilDetails(ids)
      this.passwordBeingSet = ''
    },
    didSetRandomPasswords () {
      // generate array of user : password
      const ids = []
      for (let i = 0; i < this.checkedRows.length; i++) {
        ids.push({ id: this.checkedRows[i].id, password: this.generatePassword() })
      }
      this.setPupilDetails(ids)
      this.passwordBeingSet = ''
    },
    setPupilDetails (pupilIdsArray) {
      if (pupilIdsArray.length === 0) {
        alert('No pupils selected')
        return
      }
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('PUT', 'users/me/school/pupils', { pupils: pupilIdsArray })
        .then((response) => {
          const pupils = response.data.pupils
          // console.log('aaa' + pupils)
          for (let i = 0; i < this.pupilData.length; i++) {
            for (let j = 0; j < pupils.length; j++) {
              if (this.pupilData[i].id === pupils[j].id) {
                if (pupils[j].password) {
                  this.$set(this.pupilData[i], 'password', pupils[j].password)
                  if (window.localStorage) {
                    window.localStorage.setItem('password:' + this.pupilData[i].username, pupils[j].password)
                  }
                } else if (pupils[j].locale) {
                  this.$set(this.pupilData[i], 'locale', pupils[j].locale)
                }
              }
            }
            this.checkedRows = []
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    forgetPasswords () {
      const c = confirm('Forget password on this computer? The password will still work for the pupil.')
      if (c) {
        for (let k = 0; k < this.pupilData.length; k++) {
          if (window.localStorage) {
            window.localStorage.removeItem('password:' + this.pupilData[k].username)
          }
          this.$delete(this.pupilData[k], 'password')
        }
      }
    },
    showAddModal () {
      this.showModal = true
    },
    hideAddModal () {
      this.showModal = false
    },
    async setDisplayName (pupil) {
      const p = await this.prompt({ title: 'Change display name', message: 'Enter display name (leave blank to show pupil name)', label: 'Name', defaultValue: pupil.display_name })
      if (p) {
        request('PUT', 'users/me/school/pupils/' + pupil.username, { displayName: p })
          .then((response) => {
          // var data = response.data
            for (let i = 0; i < this.pupilData.length; i++) {
              if (this.pupilData[i].id === pupil.id) {
                this.pupilData[i].display_name = p
              }
            }
          // console.log(data)
          })
          .catch((error) => {
            console.log(error)

            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    },
    getListData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/pupils', null)
        .then((response) => {
          const data = response.data
          this.pupilData = data.pupils
          this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
          if (!this.pupilData || this.pupilData.length === 0) {
            this.response = 'No Pupils'
          }

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .then(function () {
          $('.dropdown-submenu a.test').on('click', function (e) {
            $(this).next('ul').toggle()
            e.stopPropagation()
            e.preventDefault()
          })
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getGroupData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/groups', null)
        .then((response) => {
          const data = response.data
          this.groupData = data.groups

          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    beforeAddPupil () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addPupil()
        }
      })
    },
    addPupil () {
      console.log('add')
      const { name, username, email, password } = this

      $('#myModal').removeClass('in')
      $('.modal-backdrop').remove()
      $('body').removeClass('modal-open')

      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')

      const d = { name }

      if (email && email !== '') {
        d.email = email
      }

      if (username && username !== '') {
        d.username = username
      }

      if (password && password !== '') {
        d.password = password
      } else {
        d.password = this.generatePassword()
      }
      // console.log(d)
      /* Making API call to authenticate a user */
      request('POST', 'users/me/school/pupils', d)
        .then((response) => {
          this.toggleLoading()

          window.console.log('Response', response)
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            data.pupils.password = d.password
            this.pupilData = this.pupilData.concat(data.pupils)

            if (!this.pupilData || this.pupilData.length === 0) {
              this.response = 'No Pupils'
            }

            this.name = ''
            this.username = ''
            this.email = ''
            this.password = ''
            setTimeout(() => {
              this.errors.clear()
              this.$validator.reset()
            }, 10)
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    didAddPupils (d) {
      // console.log('d: ' + d)
      // this.pupilData = this.pupilData.concat(d)
      this.getListData()
      this.getGroupData()
      this.checkedRows = []
    },
    generatePassword () {
      return utils.generatePassword()
    },
    deletePupil (id) {
      const conf = confirm('Are you sure you want to delete this pupil? This cannot be undone.')
      if (conf) {
        this.deletePupils([id])
      }
    },
    deleteMultiplePupils () {
      const conf = confirm('Are you sure you want to delete these pupils? This cannot be undone.')
      if (conf) {
        const ids = []
        for (let i = 0; i < this.checkedRows.length; i++) {
          ids.push(this.checkedRows[i].id)
        }
        this.deletePupils(ids)
      }
    },
    deletePupils (pupilIdsArray) {
      if (pupilIdsArray.length === 0) {
        alert('No pupils selected')
        return
      }
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('DELETE', 'users/me/school/pupils', { pupils: pupilIdsArray.join() })
        .then((response) => {
          const data = response.data
          this.pupilData = data.pupils
          this.searchDidChange()

          // console.log('readerData: ' + this.readerData)
          if (!this.pupilData || this.pupilData.length === 0) {
            this.response = 'No Records Found'
          }
          this.checkedRows = []
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    assignToGroup (groupId) {
      $('.dropdown-submenu a.test').next('ul').hide()
      const ids = []
      for (let i = 0; i < this.checkedRows.length; i++) {
        ids.push(this.checkedRows[i].id)
      }
      this.doAssignToGroup(groupId, ids)
    },
    doAssignToGroup (groupId, pupils) {
      if (pupils.length === 0) {
        alert('No pupils selected')
        return
      }
      request('POST', 'users/me/school/groups/' + groupId + '/pupils', { pupils: pupils.join() })
        .then((response) => {
        // var data = response.data
        // this.pupilData = data.pupils
          this.getListData()
          // console.log('readerData: ' + this.readerData)
          if (!this.pupilData || this.pupilData.length === 0) {
            this.response = 'No Records Foud'
          }
          this.checkedRows = []
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    moveToGroup (groupId) {
      const c = confirm('Moved pupil(s) to ONLY this new group.')
      if (c) {
        $('.dropdown-submenu a.test').next('ul').hide()
        const ids = []
        for (let i = 0; i < this.checkedRows.length; i++) {
          ids.push(this.checkedRows[i].id)
        }
        this.doMoveToGroup(groupId, ids)
      }
    },
    doMoveToGroup (groupId, pupils) {
      if (pupils.length === 0) {
        alert('No pupils selected')
        return
      }
      request('POST', 'users/me/school/groups/' + groupId + '/pupils', { pupils: pupils.join(), move: 1 })
        .then((response) => {
        // var data = response.data
          this.getListData()
          if (!this.pupilData || this.pupilData.length === 0) {
            this.response = 'No Records Found'
          }
          this.checkedRows = []
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    toggleExtraAddPupil () {
      $('#optionalPanel').toggle()
    },
    didSetRegion () {
      console.log('save region')
      const ids = []
      for (let i = 0; i < this.checkedRows.length; i++) {
        ids.push({ id: this.checkedRows[i].id, locale: this.locale })
      }
      this.setPupilDetails(ids)
      this.clearRegionBeingSet()
    },
    clearRegionBeingSet () {
      this.locale = this.$store.state.user.school.locale
    }
  }
}
</script>
<style>
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;
}

abbr[title] {
  text-decoration: none;
}

#canMergeUsersInfoBox {
  margin-bottom: 10px;
}

/* @media not print {
  #printWrapper {
    display: none;
  }
}

@media only print {
  #contentWrapper {
    display: none;
  }
  #printWrapper {
    display: grid;
    grid-template-columns: auto auto;
  }
} */

</style>
